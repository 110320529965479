import React from 'react';
import { Layout } from '../components';
import Jumbotron from '../containers/jumbotron-cart-page';
import Menu from '../containers/menu';
import Cart from '../containers/cart';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout>
    <SEO
      title="Корзина | Мама Food"
      keywords={[`Пицца`, `доставка`, `бургеры`]}
    />
    <Jumbotron />
    <Cart />
  </Layout>
);

export default IndexPage;

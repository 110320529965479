import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  Container as BaseContainer,
  Section as BaseSection,
} from '../../components';
import Modal from '../../components/modal';
import Empty from './empty';
import Table from './table';
import Form from './form';
import { Button } from '../../components/ui';
import { media } from '../../styles/helpers';
import { offsets } from '../../styles';

const Section = styled(BaseSection)`
  padding-top: ${offsets.lg};
`;

const Container = styled(BaseContainer)`
  margin: 0 -${offsets.ms};
  flex-wrap: wrap;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${offsets.sm} 0;

  ${media.md`
  padding: ${offsets.sm};
  `}
`;

const TableWrapper = styled(Wrapper)`
  ${media.md`
    width: 70%;
  `}
`;

const FormWrapper = styled(Wrapper)`
  ${media.md`
    width: 30%;
  `}
`;

const ModalButton = styled(Button)`
  align-self: flex-start;
  margin-top: ${offsets.sm};
  width: 90px;
`;

class Cart extends PureComponent {
  static propTypes = {
    isEmpty: PropTypes.bool,
  };

  state = {
    isSuccessModalOpen: false,
  };

  toggleModal = () =>
    this.setState({ isSuccessModalOpen: !this.state.isSuccessModalOpen });

  render() {
    const { isEmpty } = this.props;
    return (
      <Fragment>
        <Section>
          <Container>
            {isEmpty ? (
              <Empty />
            ) : (
              <Fragment>
                <TableWrapper>
                  <Table />
                </TableWrapper>
                <FormWrapper>
                  <Form toggleSuccessModal={this.toggleModal} />
                </FormWrapper>
              </Fragment>
            )}
          </Container>
        </Section>
        <Modal
          isOpen={this.state.isSuccessModalOpen}
          toggle={() => this.toggleModal()}
          title="Спасибо за заказ"
        >
          Мы свяжемся с Вами для уточнения деталей в ближайшее время.
          <ModalButton onClick={this.toggleModal}>Ок</ModalButton>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ app }) => ({
  isEmpty: !app.basket.length,
});

export default connect(mapStateToProps)(Cart);

import React from 'react';
import styled from 'styled-components';
import { offsets } from '../../styles';

const TH = styled.th`
  padding: ${offsets.sm};
  text-align: left;
`;

export default () => (
  <thead>
    <tr>
      <TH>Наименование</TH>
      <TH>Количество</TH>
      <TH>Цена</TH>
      <TH />
    </tr>
  </thead>
);

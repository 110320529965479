import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { Button } from '../../components/ui';
import { colors, offsets, fontSizes, transitions } from '../../styles';
import { updateBasket } from '../../store/reducers/app';
import { getBasketItemsView } from './helpers';
import { clearBasket } from '../../helpers/basket';

const Shape = styled.form`
  padding: ${offsets.md};
  font-size: ${fontSizes.md};
  height: 100%;
  background: #fff;
  box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
`;

const Title = styled.div`
  margin: ${offsets.sm} 0;
  font-weight: bold;
`;

const inputStyles = css`
  background: transparent;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${colors.gray};
  outline: none;
  padding: ${offsets.sm};
  margin-bottom: ${offsets.md};
  transition: ${transitions.base};

  &:hover {
    border-color: ${colors.textGray};
  }

  &:active,
  &:focus {
    border-color: ${colors.textGray};
    box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.2);
  }
`;

const Input = styled.input`
  ${inputStyles};
`;

const Textarea = styled.textarea`
  ${inputStyles}
  resize: none;
`;

class Form extends PureComponent {
  static propTypes = {
    rawItems: PropTypes.array,
    updateBasket: PropTypes.func.isRequired,
    toggleSuccessModal: PropTypes.func.isRequired,
  };

  state = {
    fetching: false,
    isModalOpen: false,
  };

  toggleFetching = () => this.setState({ fetching: !this.state.fetching });

  toggleModal = () => this.setState({ isModalOpen: !this.state.isModalOpen });

  handleSubmit = (e) => {
    e.preventDefault();

    const { result: basketData } = getBasketItemsView(this.props.rawItems);
    const formData = new FormData(e.nativeEvent.target);

    const formJson = Array.from(formData.entries()).reduce(
      (memo, pair) => ({
        ...memo,
        [pair[0]]: pair[1],
      }),
      {}
    );

    const data = {
      contacts: formJson,
      data: basketData,
    };

    this.toggleFetching();

    fetch('https://mama-food-perm.ru/ajax.php', {
      method: 'POST',
      body: JSON.stringify(data),
    }).then(() => {
      this.toggleFetching();
      this.props.updateBasket(clearBasket());
      this.props.toggleSuccessModal();
      if (window.yaCounter52712911) {
        //ym(52712911, 'reachGoal', 'order');
        yaCounter52712911.reachGoal('order');
      }

      try {
        // testing api
        fetch('https://api.mama-food-perm.ru/public/orders', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: data.contacts.name,
            phoneNumber: data.contacts.phone,
            address: data.contacts.address,
            items: data.data.reduce((acc, item) => {
              acc[item.id] = item.count;
              return acc;
            }, {}),
          }),
        });
      } catch (e) {
        console.error(e);
        // nothing to do while testing
      }
    });
  };

  render() {
    return (
      <Shape onSubmit={this.handleSubmit}>
        <Title>Оформление заказа</Title>
        <Input
          name="name"
          type="text"
          autocomplete="off"
          placeholder="Ваше имя"
          required
        />
        <Input
          name="phone"
          type="tel"
          autocomplete="off"
          placeholder="Ваш телефон"
          required
        />

        <Textarea name="address" placeholder="Адрес доставки" required />
        <Button type="submit">Отправить заказ</Button>
      </Shape>
    );
  }
}

const mapStateToProps = ({ app }) => ({
  rawItems: app.basket,
});

export default connect(mapStateToProps, { updateBasket })(Form);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import THead from './thead';
import { removeItemById } from '../../helpers/basket';
import { colors, offsets, fontSizes, transitions } from '../../styles';
import { media } from '../../styles/helpers';
import { updateBasket } from '../../store/reducers/app';
import { getBasketItemsView } from './helpers';

const TD = styled.td`
  padding: ${offsets.sm};
  border-bottom: 1px solid ${colors.gray};
`;

const Summ = styled.p`
  padding: ${offsets.sm};
  margin: 0;
  text-align: right;
`;

const RemoveTD = styled(TD)`
  transition: color ${transitions.base};
  cursor: pointer;
  text-align: right;

  &:hover {
    color: ${colors.primary};
  }
`;

const Shape = styled.div`
  padding: ${offsets.xs};
  height: 100%;
  font-size: ${fontSizes.xs};
  background: #fff;
  box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow: scroll;
  border: 15px solid #fff;

  & table {
    width: 100%;
  }

  ${media.md`
    overflow: hidden;
    border: none;
    font-size: ${fontSizes.md};
    padding: ${offsets.md};
  `}
`;

const Table = ({ rawItems, updateBasket }) => {
  const { result, sum } = getBasketItemsView(rawItems);
  return (
    <Shape>
      <table cellSpacing={0}>
        <THead />
        <tbody>
          {result.map((item, idx) => (
            <tr key={`basket-item-${idx}`}>
              <TD dangerouslySetInnerHTML={{ __html: item.name }}/>
              <TD>{`${item.count} шт`}</TD>
              <TD>{`${item.price} р.`}</TD>
              <RemoveTD
                onClick={() => {
                  const items = removeItemById(item.id);
                  updateBasket(items);
                }}
              >
                Удалить
              </RemoveTD>
            </tr>
          ))}
        </tbody>
      </table>
      <Summ>{`Итого: ${sum} р.`}</Summ>
    </Shape>
  );
};

Table.propTypes = {
  rawItems: PropTypes.array.isRequired,
  updateBasket: PropTypes.func.isRequired,
};

const mapStateToProps = ({ app }) => ({
  rawItems: app.basket,
});

export default connect(
  mapStateToProps,
  { updateBasket }
)(Table);

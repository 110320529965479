import { menuItems } from '../../data';

const allItems = [].concat.apply(
  [],
  Object.values(menuItems).map(({ items }) => items)
);

export const getBasketItemsView = rawItems => {
  const result = [];
  let sum = 0;

  rawItems.forEach(item => {
    const fullItem = allItems.find(({ id }) => id === item.id);

    if (fullItem) {
      result.push({
        id: fullItem.id,
        name: fullItem.name,
        price: fullItem.price,
        count: item.count,
      });

      sum += fullItem.price * item.count;
    }
  });

  return {
    sum,
    result,
  };
};

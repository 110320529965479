import React from 'react';
import { Link as BaseLink } from 'gatsby';
import styled from 'styled-components';
import { colors, transitions } from '../../styles';

const EmptyContainer = styled.div`
  width: 100%;
  height: 35vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.2);
`;

const Link = styled(BaseLink)`
  position: relative;
  color: ${colors.primary};

  &::before {
    content: '';
    position: absolute;
    bottom: -2px;
    right: 0;
    left: 0;
    height: 2px;
    background: ${colors.primary};
    transform: scaleX(0);
    transform-origin: 0% 50%;
    transition: transform ${transitions.base};
  }

  &:hover {
    color: ${colors.primary};
    &:before {
      transform: scaleX(1);
    }
  }
`;

export default () => (
  <EmptyContainer>
    <h3>
      В вашей корзине пусто.
      <br />
      <Link to="/order">Закажите что-нибудь :)</Link>
    </h3>
  </EmptyContainer>
);
